<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="9">
                <v-card flat class="mr-3" :loading="loading">
                    <v-card-title>
                        <v-spacer></v-spacer>

                        <v-btn text color="primary" :to="'/orders/' + reservation.order.id" 
                            v-if="reservation != null && reservation.order != null">Order #{{ reservation.order.foreignId }}</v-btn>
                    </v-card-title>
                    <v-card-text v-if="reservation != null">
                        <v-row>
                            <v-col cols="4" align-self="start">
                                <customer-info :customer="reservation.customer" :edit="false" :avatar="false" show-measurements>
                                </customer-info>
                            </v-col>

                            <v-col cols="4">
                                <div class="display-1 font-weight-light grey--text text--darken-4">Event</div>
                                <div v-if="reservation.eventId == 0">This reservation is not associated with any event.</div>
                                <v-row no-gutters v-else>
                                    <v-col cols="3"><div>Code:</div></v-col>
                                    <v-col cols="3"><div>{{ reservation.event.foreignKey }}</div></v-col>
                                    <v-col cols="3"><div>Date:</div></v-col>
                                    <v-col cols="3"><div>{{ reservation.event.eventDate }}</div></v-col>
                                    <v-col cols="3"><div>Occasion:</div></v-col>
                                    <v-col cols="3"><div>{{ reservation.event.occasion }}</div></v-col>
                                    <v-col cols="3"><div>Role:</div></v-col>
                                    <v-col cols="3"><div>{{ reservation.eventRole }}</div></v-col>
                                    <v-col cols="3"><div>Type:</div></v-col>
                                    <v-col cols="3"><div>{{ reservation.event.eventType }}</div></v-col>
                                    <v-col cols="3"><div>Location:</div></v-col>
                                    <v-col cols="3"><div>{{ reservation.event.location }}</div></v-col>
                                </v-row>

                                <v-divider inset class="mr-5 my-5 mb-3"></v-divider>

                                <div class="display-1 font-weight-light grey--text text--darken-4">Rent Details</div>

                                <rent-details :use-date="reservation.useDate" :want-date="reservation.wantDate" :want-date-time="wantDateTime" 
                                    :return-date="reservation.returnDate"  :pickup-location="reservation.pickupLocationID" :event-role-id="reservation.eventRoleId" 
                                    :comments="reservation.comments" readonly ref="rentDetails"></rent-details>
                            </v-col>

                            <v-col cols="4">
                                <div class="display-1 font-weight-light grey--text text--darken-4">Rent Items</div>

                                <package-viewer :value="reservationPackage" :store-id="reservation.storeId" :pickup-location="reservation.pickupLocation" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card flat>
                    <v-card-title class="headline font-weight-light grey--text text--darken-4">History</v-card-title>
                    <v-card-text class="px-0">
                        <history obj-type="reservation" :id="reservationId"  :showForm="false" ref="history" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { API } from '@/inc/api';
import { _st } from '@/softech';
import CustomerInfo from '@/components/CustomerInfo.vue';
import RentDetails from '@/components/RentDetails.vue';
import PackageViewer from '@/components/PackageViewer.vue';
import History from '@/components/History.vue';

export default {
    props: {
        reservationId: { type: [String, Number ], default: 0 }
    },
    data: () => ({
        reservation     : null,
        loading         : false,
    }),
    computed: {
        reservationPackage() {
            if( this.reservation == null )
                return {};

            return {
                items                   : this.reservation.items,
                promoCodes              : this.reservation.promoCodes,
                charges                 : this.reservation.charges,
                subtotalOverwrite       : this.reservation.subtotalOverwrite,
                subtotalOverwriteReason : this.reservation.subtotalOverwriteReason
            };
        },
        wantDateTime() {
            if( this.reservation == null )
                return '';
            
            return this.reservation.wantDate.split(' ')[1];
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/admin/reservation/${this.reservationId}`);
                this.loading = false;

                if( res.data.status === false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                if( _st.isNUE( res.data.data.id ) ) {
                    this.$router.push('/404');
                    return;
                }

                this.reservation = res.data.data;
                this.reservation.customer.rentalMeasurements = this.reservation.measurements;

                this.$refs.history.getHistory();
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage(error, 'error');
            }
        }
    },
    components: {
        CustomerInfo, RentDetails, PackageViewer, History
    }
}
</script>

<style lang="scss" scoped>

</style>